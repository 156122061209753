import { defineStore } from 'pinia'
import Vue from 'vue'
import { ApiCall } from '@/store/ApiCall'

export const useTaskStore = defineStore({
    id: 'TaskStore',
    state(){ 
        return {
            list: [],
            listAll: [],
            filter: 'active',//'active','done'
            search: '',
            modalSearch: '',
            autocompleterSearch: '',
            projects: [],
            project: [],
            errors: [],
            maxAutocompleterElements: 10 
        }
    },
    getters:{
        count: function(state){
            return state.list.length
        },
        tasks: function(state){
            let activeParam = -1;
            if ( state.filter == 'active') activeParam = 0;
            else if ( state.filter == 'done' ) activeParam = 1;

            return state.list.filter((el) => { 
                return el.t.toLowerCase().indexOf(state.search.toLowerCase()) != -1
                    && (el.ts == activeParam || activeParam == -1)
                })
        },
        tasksAutocompleter: function(state){
            let resultTasks = [];
            for(let i=0;i<state.listAll.length && i < state.maxAutocompleterElements;i++){
                let task = state.listAll[i];
                if ( task.t.toLowerCase().indexOf(state.autocompleterSearch.toLowerCase()) == -1 ) continue
                let tags = task['tags'] != null ? task['tags'].split(',') : ''
                resultTasks.push( { 'project': task['pt'], 'uk':task['uk'], 'task':task['t'], 'client': task['ct'], 'tags': tags, 
                    'cs': task['cs'], 'idb': task['idb'] } 
                );
            }
            console.log('resultTasks: ' + resultTasks.length)
            return resultTasks
        },
        tasksAll: function(state){
            let resultTasks = { 'favs': { 'projects': {}, 'count': 0}, 'clients': {}};
            let openAll = state.modalSearch.trim() != ''
            for(let i=0;i<state.listAll.length;i++){
                let task = state.listAll[i];
                if (  task.t != null && task.t.toLowerCase().indexOf(state.modalSearch.toLowerCase()) == -1 ) continue
                if ( !resultTasks.clients[task['cuk']] ) resultTasks.clients[task['cuk']] = { 'title': task['ct'], 'projects':{}, 'count':0, 'isOpenTasks':true};

                if ( !resultTasks.clients[task['cuk']].projects[task['puk']] ) {
                    resultTasks.clients[task['cuk']].projects[task['puk']] = {
                        'title': task['pt'],
                        'isOpenTasks':openAll,
                        'count': 0,
                        'isFav': task['pf'],
                        'tasks': [],
                        'puk': task['puk']
                    }
                    resultTasks.clients[task.cuk].count ++
                }
                resultTasks.clients[task['cuk']].projects[task['puk']].tasks.push(task)
                
                if ( task.t != null )
                    resultTasks.clients[task['cuk']].projects[task['puk']].count ++ //= resultTasks.clients[task['cuk']].projects[task['puk']].tasks.length

                if ( task['tdf'] == 1) {
                    if ( !resultTasks.favs.projects[task['puk']] ) {
                        resultTasks.favs.projects[task['puk']] = {
                            'title': task['pt'],
                            'isOpenTasks': openAll,
                            'count': 0,
                            'isFav': task['pf'] == 1,
                            'tasks': [],
                            'puk': task['puk']
                        }
                        resultTasks.favs.count ++
                    }
                    resultTasks.favs.projects[task['puk']].tasks.push(task)
                    resultTasks.favs.projects[task['puk']].count = resultTasks.favs.projects[task['puk']].tasks.length
                }
                
            }
            return resultTasks;
        },
        filterValue: function(state){
            return state.filter
        },
        searchValue: function(state){
            return state.search
        },
        modalSearchValue: function(state){
            return state.modalSearch
        }
    },
    actions:{
        setAllProjectFav(uk, isFav){
            for(let i=0;i<this.listAll.length;i++){
                if ( this.listAll[i].puk == uk ){
                    this.listAll[i].pf = isFav
                }
            }
        },
        setAllTaskFav(uk, isFav){
            for(let i=0;i<this.listAll.length;i++){
                if ( this.listAll[i].uk == uk ){
                    this.listAll[i].tf = isFav
                }
            }
        },
        async setProject(project, updateTasks) {
            this.$patch((state) => {
                state.project = project
                this.listing(project, updateTasks)
            })
        },
        async changeSearch(search){
            this.$patch((state) => {
                state.search = search;
            })
        },
        async changeModalSearch(search){
            console.log('New modal search: ' + search)
            this.$patch((state) => {
                state.modalSearch = search;
            })
        },
        async changeAutocompleterSearch(search){
            this.$patch((state) => {
                state.autocompleterSearch = search;
            })
        },
        async changeFilter(filter){
            this.$patch((state) => {
                state.filter = filter;
            })
        },
        async setTaskStatus(uk, suk, scs){
            try{
                const optionsData = await ApiCall.options()
                if ( optionsData.statusText == 'OK') {
                    const response = await ApiCall.post(
                        'ws/{wk}/tasks/' + uk + '/statuses/' + suk
                    )
                    this.$patch((state) => {
                        state.list.push(response.data.message)
                    })
                    
                    for(let i=0;i<this.list.length;i++){
                        if ( this.list[i].uk == uk) {
                            this.$patch((state) => {
                                console.log('TaskStore: Update suk for '+ state.list[i].task_t + ' scs: ' + scs)
                                state.list[i].suk = suk
                                state.list[i].scs = scs
                            })
                        }
                    }
                    
                    return response
                }
            }catch(error){
                this.$patch((state) => {
                    state.errors.push(error);
                })
            }
        },
        async setTaskTag(tk, tgk, checked){
            try{
                const optionsData = await ApiCall.options()
                if ( optionsData.statusText == 'OK') {
                    const response = await ApiCall.post(
                        'ws/{wk}/tasks/' + tk + '/tags/' + tgk + '/' + checked
                    )
                    this.$patch((state) => {
                        state.list.push(response.data.message)
                    })
                    return response
                }
            }catch(error){
                this.$patch((state) => {
                    state.errors.push(error);
                })
            }
        },
        async listingAll(){
            const optionsData = await ApiCall.options()
            if ( optionsData.statusText.toLowerCase() == 'ok') {
                try  {
                    const response = await ApiCall.get(
                        'ws/{wk}/tasks');
                    
                    if ( response.data.result=='ok') {
                        this.$patch((state) => {
                            state.listAll = response.data.message
                        })
                    }
                    else {
                        this.$patch((state) => {
                            state.errors.push(response.data.message);
                        })
                    }
                }
                catch (error) {
                    this.$patch((state) => {
                        state.errors.push(error);
                    })
                }
            } else {
                this.$patch((state) => {
                    state.errors.push(optionsData.message)
                })
            }
        },
        async listing(project, updateTasks){
            const optionsData = await ApiCall.options()
            if ( optionsData.statusText.toLowerCase() == 'ok') {
                try  {
                    const response = await ApiCall.get(
                        'ws/{wk}/projects/' + project.uk + '/tasks');
                    
                    if ( response.data.result=='ok') {
                        this.$patch((state) => {
                            state.list = response.data.message
                            updateTasks(state.list)
                        })
                    }
                    else {
                        this.$patch((state) => {
                            state.errors.push(response.data.message);
                        })
                    }
                }
                catch (error) {
                    this.$patch((state) => {
                        state.errors.push(error);
                    })
                }
            } else {
                this.$patch((state) => {
                    state.errors.push(optionsData.message)
                })
            }
        },
        async insert(dataInsert){
            // data should contain title field
            if ( dataInsert['title'].length == 0) return;
            console.log(dataInsert['title'])
            
            try{
                const optionsData = await ApiCall.options()
                if ( optionsData.statusText == 'OK') {
                    console.log('Insert Method: ' + dataInsert.title)
                    const response = await ApiCall.post(
                        'ws/{wk}/tasks',
                        dataInsert
                    )
                    this.$patch((state) => {
                        state.list.push(response.data.message)
                    })
                    return response
                }
            }catch(error){
                this.$patch((state) => {
                    state.errors.push(error);
                })
            }
        },
        async update(item, form){
            const optionsData = await ApiCall.options()
            if ( optionsData.statusText == 'OK') {
                const response = await ApiCall.put(
                    'ws/{wk}/tasks/' +item.uk,
                    form
                )
                for(let i=0;i<this.list.length;i++){
                    if ( this.list[i].uk == item.uk) {
                        this.$patch((state) => {
                            state.list[i].t = form.title
                        })
                        break
                    }
                }
                return response
            }
        },
        async remove(item) {
            ApiCall.options().then(response => {
                ApiCall.delete('ws/{wk}/tasks/' + item.uk)
                .then(response => {
                    if ( response.data.result!='ok')
                        this.$patch((state) => {
                            state.errors.push(response.data.message);
                        })
                    else {
                        for(let i=0;i<this.list.length;i++){
                            if ( this.list[i].uk == item.uk) {
                                this.$patch((state) => {
                                    state.list.splice(i, 1)
                                })
                                break
                            }
                        }
                    }
                })
                .catch(e => {
                    this.errors.push(e)
                })
            })
            .catch(e => {    
                this.$patch((state) => {
                    state.errors.push(e);
                })
            }) 
        },
        async duplicate(item, updateMethod) {
            ApiCall.otions().then(response => {
                ApiCall.post('ws/{wk}/projects/' + item.uk + '/duplicate')
                .then(response => {
                    if ( response.data.result=='ok') {
                        this.$patch((state) => {
                            let newItem = response.data.message
                            state.list.push(newItem)
                            updateMethod(newItem)
                        })
                    } else {
                        this.$patch((state) => {
                            state.errors.push(response.data.message);
                        })
                    }
                })
                .catch(e => {
                    this.errors.push(e)
                })
            })
            .catch(e => {    
                this.$patch((state) => {
                    state.errors.push(e);
                })
            })
        },
        
        async fav(tk, fav){
            try{
                const optionsData = await ApiCall.options()
                if ( optionsData.statusText == 'OK') {
                    const response = await ApiCall.post(
                        'ws/{wk}/tasks/'+tk+'/fav',
                        {
                            is_fav: fav ? 1 : 0
                        }
                    )
                    
                    for(let i=0;i<this.list.length;i++){
                        if ( this.list[i].uk == tk) {
                            this.$patch((state) => {
                                state.list[i].f = fav
                            })
                            break
                        }
                    }
                    return response
                }
            }catch(error){
                this.$patch((state) => {
                    state.errors.push(error);
                })
            }
        }
    }
})